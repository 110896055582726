<template>
    <div class="video-tips">
        <div class="tips-warpper flex">
            <div class="upload-tips">
                <div class="upload" v-if="statusFlag===1">
                    <img class="up1" src="../../../assets/img/company/video_up_1.png" alt="">
                    <img class="up2" src="../../../assets/img/company/video_up_2.png" alt="">
                    <div class="text34">正在上传中...</div>
                </div>
                <div class="v-success" v-else-if="statusFlag===2">
                    <img src="../../../assets/img/company/video_success.png" alt="">
                    <div class="text34">已上传成功...</div>
                </div>
                <div class="v-error" v-else>
                    <img src="../../../assets/img/company/video_error.png" alt="">
                    <div class="text34">上传失败...</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        statusFlag: {
            type: Number,
            default: 1 //1上传中、2成功、3失败
        }
    }
}
</script>
<style lang="scss" scoped>
@keyframes top {
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(-25px);
    }
}
@import '../../../assets/css/global.scss';
    .video-tips{
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: .25);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000;
        .tips-warpper{
            position: absolute;
            box-sizing: border-box;
            justify-content: center;
            align-items: center;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            background: #fff;
            width: 450px;
            height: 300px;
            .upload-tips{
                .upload{
                    position: relative;
                    text-align: center;
                    img{
                        width: 100px;
                        height: 100px;
                        &.up2{
                            position: absolute;
                            left: 0;
                            top: 0;
                            animation: top 2.0s infinite;
                        }
                    }
                    
                }
                .v-success{
                    text-align: center;
                    img{
                        width: 75px;
                        height: 75px;
                    }
                }
                .v-error{
                    text-align: center;
                    img{
                        width: 75px;
                        height: 75px;
                    }
                }   
            }
        }
        .text34{
            margin-top: 15px;
            font-size: 16px;
            color: #999;
        }
}
</style>