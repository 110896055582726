<template>
    <div class="slide-show">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item >广告管理</el-breadcrumb-item>
            <el-breadcrumb-item>广告管理</el-breadcrumb-item>
        </el-breadcrumb>
         <el-card class="box-card" style="min-height:100%">
            <div class="flex align-center justify-between">
                <div class="flex align-center">
                    <el-select v-model="keyword" placeholder="请选择分类">
                        <el-option label="不限" :value="null"></el-option>
                        <el-option label="轮播图" :value="1"></el-option>
                        <el-option label="长广告" :value="2"></el-option>
                        <el-option label="短广告" :value="3"></el-option>
                    </el-select>
                    <el-button type="primary" class="margin-left-sm" @click="getAllSlideShow">搜索</el-button>
                </div>
                <div class="create"><el-button type="primary" icon="el-icon-circle-plus-outline" @click="openAddSlideShow">新增广告</el-button></div>
            </div>
            
            <el-table
                border
                :data="slideShowList"
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                style="width: 100%">
                <el-table-column
                    prop="imgPath"
                    label="图片"
                    align="center">
                    <template slot-scope="scope">
                        <el-popover
                            width="500"
                            placement="top"
                            trigger="hover">
                            <slot name="content">
                                <img :src="scope.row.imgPath" width="100%" alt="">
                            </slot>
                            <el-image
                                slot="reference"
                                style="width:180px"
                                :src="scope.row.imgPath"
                                fit="cover"></el-image>
                        </el-popover>
                        <!-- <show-img
                            :url="scope.row.imgPath"
                            width="700"
                            wid="180px"
                        ></show-img> -->
                    </template>
                </el-table-column>
                <el-table-column
                label="类型"
                >
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.type==1" >轮播图</el-tag>
                        <el-tag type="primary" v-if="scope.row.type==2" >长广告</el-tag>
                        <el-tag type="primary" v-if="scope.row.type==3" >短广告</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="160"
                    align="center">
                    <template slot-scope="scope">
                       <el-button
                            type="primary"
                            @click="editSlideShow(scope.row)"
                            >修改
                        </el-button>
                        <el-button
                            type="danger"
                            v-if="scope.row.id!=4"
                            @click="deleteSlideShow(scope.row)"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    prop="imgPath"
                    label="图片地址"
                    width="180">
                </el-table-column> -->
            </el-table>

            <el-dialog
                :title="isFlag"
                :visible.sync="addSlideShowVisible"
                width="1000px"
                :before-close="handleClose">
                <el-form ref="slideShowFormRef" :model="slideShowForm" label-width="110px">
                    <el-form-item label="类别：">
                        <el-radio v-model="slideShowForm.type" :label="1">轮播图</el-radio>
                        <el-radio v-model="slideShowForm.type" :label="2">长广告</el-radio>
                        <el-radio v-model="slideShowForm.type" :label="3">短广告</el-radio>
                    </el-form-item>
                    <el-form-item label="图片：">
                        <el-tooltip class="item" effect="dark" content="" placement="top-start">
                            <upload-img @changeurl="changeSlideShowPic" :picurl="slideShowForm.imgPath" @uploading="uploading"></upload-img>
                        </el-tooltip>
                    </el-form-item>

                    <el-form-item label="url" prop="userName">
                        <el-input
                                v-model="slideShowForm.urlPath"
                                style="width: 400px; margin-right:20px;"
                        ></el-input>
                        <span>url需要完整链接,示例： https://www.baidu.com</span>
                    </el-form-item>

                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addSlideShowVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveSlideShow">确 定</el-button>
                </span>
            </el-dialog>
            <video-tips v-if="videoStatus" :statusFlag="statusFlag"></video-tips>
         </el-card>
    </div>
</template>
<script>
import uploadImg from '../../pages/recruitment/components/UploadImg'
import VideoTips from '../../pages/recruitment/components/VideoTips'
import companyRequest from '../../api/company'
export default {
    components: {
        uploadImg,
        VideoTips
    },
    mounted() {
        this.getAllSlideShow()
    },
    data() {
        return {
            keyword:null,
            flag: true,
            addSlideShowVisible: false, //添加修改轮播图弹框
            slideShowList: [], //轮播图列表
            slideShowForm: { //添加修改轮播图表单
                id: null,
                type:1,
                imgPath: '', //图片路径
                urlPath:''  // 轮播图外链
            },
            videoStatus: false,
            statusFlag: 1,

        }
    },
    methods: {
        handleClose() {
            this.addSlideShowVisible = false
            this.slideShowForm.id = null
            this.slideShowForm.imgPath = ''
        },
        openAddSlideShow() {
            this.flag = true
            this.slideShowForm.id = null
            this.slideShowForm.imgPath = ''
            this.addSlideShowVisible = true
        },
        //获取轮播图列表
        getAllSlideShow() {
            companyRequest.getAllSlideShow({type:this.keyword}).then((res) => {
                if (res.code === 200) {
                    this.slideShowList = res.data
                    console.log('slideShowList', this.slideShowList)
                }
            })
        },
        saveSlideShow() {
            console.log('slideShowForm', this.slideShowForm)
            if (!this.slideShowForm.imgPath) {
                this.$message.warning('请上传轮播图')
                return
            }

            if (!this.slideShowForm.urlPath) {
                this.$message.warning('请输入轮播图指向的链接')
                return;
            }

            const reg = /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/;
            let isUrl = reg.test(this.slideShowForm.urlPath)
            if(!isUrl){
                console.log("请输入正确的url地址")
                this.$message.warning('请输入正确的url地址')
                return;
            }




            companyRequest.addSlideShow(this.slideShowForm).then((res) => {
                if (res.code === 200) {
                    this.$message.success('操作成功~')
                    this.getAllSlideShow()
                    this.addSlideShowVisible = false
                }
            }).catch((err) => {
                this.$message.error(err)
                this.addSlideShowVisible = false
            })
        },
        editSlideShow(row) {
            this.flag = false
            this.slideShowForm.id = row.id
            this.slideShowForm.imgPath = row.imgPath
            this.slideShowForm.urlPath = row.urlPath
            this.slideShowForm.type = row.type
            this.addSlideShowVisible = true
        },
        deleteSlideShow(row) {
            this.$confirm('此操作将永久删除该轮播图, 是否继续?', '删除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                companyRequest.deleteSlideShow({id: row.id}).then((res) => {
                    if (res.code === 200) {
                        this.$message.success('删除成功~')
                        this.getAllSlideShow()
                    }
                }).catch((err) => {
                    this.$message.error(err)
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })          
            })
        },
        changeSlideShowPic(url) {
            this.slideShowForm.imgPath = url
            this.statusFlag = 2
            setTimeout(() => {
                this.videoStatus = false
            }, 400)
        },
        uploading() {
            this.videoStatus = true
            this.statusFlag = 1
        }
    },
    computed: {
        isFlag() {
            return this.flag ? '添加轮播图' : '修改轮播图'
        }
    }
}
</script>
<style lang="scss" scoped>
    .slide-show{
        height: 100%;
        .create{
            margin-bottom: 20px;
            display: flex;
            justify-content: flex-end;
        }
        .mycolor{
            color: #fff;
        }
        .el-card{
            margin-top: 20px;
        }
        .big-pic{
            img{
                width: 400px;
            }
        }
        .small-pic{
            display: flex;
            justify-content: center;
            img{
                width: 180px;
            }
        }
        .hover-pic{
            display: block;
            width: 700px;
        }
    }
</style>