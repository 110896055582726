<template>
    <div>
        <el-upload
        class="avatar-uploader"
        action="/api/qiniu/getImageUrl"
        :show-file-list="false"
        :on-progress="progress"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload">
        <img v-if="picurl" :src="picurl" style="border-radius: 10px; overflow: hidden;" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon" style="border: #e8e8e8 solid 1px;border-radius: 10px; overflow: hidden;"></i>
        </el-upload>
    </div>
    <!-- <div>
        <el-upload
        class="avatar-uploader"
        action="/api/qiniu/getImageUrl"
        :show-file-list="false"
        :on-progress="progress"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload">
        <div class="imgStyle" :style="imgStyle" style="position: relative;">
          <img v-if="picurl" :src="picurl" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon" style="border: #e8e8e8 solid 1px;border-radius: 10px; overflow: hidden;"></i>
        </div>
        </el-upload>
    </div> -->
</template>
<script>
export default {
    data() {
      return {
        imageUrl: '',
        notice: null,
      };
    },
    props:{
      picurl:{
        type: String,
        dafault: ''
      },
      //外部传入的特定样式
      imgStyle: {
        type: Object,
        default() {
          return {
            width: '178px',
            height: '178px',
            overflow: 'hidden'
          }
        }
      }
    },
    watch: {
      imageUrl(newVal) {
         this.$emit("changeurl",newVal)
      }
    },
    methods: {
      upLoad(){

      },
      handleAvatarSuccess(res, file) {
        // console.log(res)
        this.imageUrl =res.data.message;
        // this.notice.close()
        // this.url= this.imageUrl
        // this.picurl= res.data.message
        // this.$emit("changeurl",this.picurl);

      },
      progress() {
        this.$emit('uploading', 1)
        // this.notice = this.$notify({
        //   title: '上传中',
        //   type: 'warning',
        //   message: '图片正在上传,请稍后',
        //   offset: 150
        // })
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 5;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG 格式和PNG格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 5MB!');
        }
        return isJPG && isLt2M;
      },
    
    }
  }
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .imgStyle {
  width: 178px;
  height: 178px;
  // border: 1px solid #eee;
  border-radius: 10px;
  position: relative;
  .el-icon-plus{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
  
</style>
